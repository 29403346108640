<script setup lang="ts">
import {onMounted, ref, computed} from 'vue'
import TocList from './tocList.vue'
import {slugifyWithCounter} from '@sindresorhus/slugify'

const props: any = defineProps({
  contentSelector: {
    type: String,
    required: true
  },
  loading: {
    type: Boolean
  }
})
const emits = defineEmits(['listHeading'])

interface ScrollElement {
  item: any
  top: number
  bottom: number
}

const slugify = slugifyWithCounter()

const headings: any = ref([])

const groupedHeadings = computed(() => {
  let items: any = [...headings.value]
  for (let i = items.length - 1; i >= 0; i--) {
    let currentItem = items[i]

    let parentItem = items.findLast((item: any, index: number) => {
      return item.level < currentItem.level && index < i
    })

    if (parentItem) {
      parentItem.subheadings.unshift(currentItem)
      items.splice(i, 1)
    }
  }

  const numberHeadings = (headings: any, prefix = '') => {
    headings.forEach((heading: any, index: number) => {
      const levelPrefix = `${prefix}${index + 1}.`

      heading.number = prefix ? `${prefix}${index + 1}` : `${index + 1}`

      if (heading.subheadings.length > 0) {
        numberHeadings(heading.subheadings, `${levelPrefix}`)
      }
    })
  }

  numberHeadings(items)

  emits('listHeading', items)
  return items
})
const active = ref<string | any>(null)

const handleScroll = () => {
  const windowHeight = window.innerHeight
  const scrollElements: ScrollElement[] = []

  headings.value?.forEach((item: any) => {
    const _id = document.getElementById(item.id)
    if (!_id) return
    const {top, bottom} = _id.getBoundingClientRect()
    scrollElements.push({item: item, top, bottom})
  })

  for (const {item, top, bottom} of scrollElements) {
    if (top < windowHeight / 2 && bottom > 0) {
      active.value = item.id
      item.active = true
      return
    } else {
      item.active = false
    }
  }
}

function removeNumbersAndDotsPrefix(inputString: string) {
  const regex = /^[0-9\.]+(.*)/
  const match = inputString.match(regex)

  if (match && match[1]) {
    return match[1]
  } else {
    return inputString
  }
}

onMounted(() => {
  window.document
      .querySelector(props.contentSelector)
      .querySelectorAll('h1, h2, h3, h4, h5, h6')
      .forEach((el: any) => {
        let id = slugify(el.innerText)
        el.setAttribute('id', id)
        headings.value.push({
          id: id,
          level: parseInt(el.tagName.charAt(1), 10),
          content: removeNumbersAndDotsPrefix(el.innerText),
          subheadings: []
        })
      })
  window.addEventListener('scroll', handleScroll)
})
</script>

<template>
  <div class="">
    <TocList :items="groupedHeadings" :active="active" :loading="loading"/>
  </div>
</template>
